import  { useState, useCallback } from "react";
export const useDarkMode = () => {
  
  const [theme, setTheme] = useState(() => {
    const cachedTheme = window.localStorage.getItem("theme");
    if (cachedTheme) {
      return cachedTheme
    }
    return window.matchMedia('(prefers-color-scheme: dark)').matches ? "dark" : "light"
  });

  const setMode = useCallback((mode) => {
    window.localStorage.setItem("theme", mode);
    setTheme(mode);
  }, []);

  const themeToggler = useCallback(() => {
    theme === "light" ? setMode("dark") : setMode("light");
  }, [setMode, theme]);

  return [theme, themeToggler];
};
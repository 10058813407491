import styled from "styled-components";
import { NoSelect } from "./NoSelect.Styled";
import { BorderShadow } from "./BorderShadow";

const formHeightDesktop = () => {
  return "560px";
};

const formHeightMobile = () => {
  return "530px";
};

const Form = styled.form`
  span {
    font-size: 2.3em;
    justify-content: center;
    display: flex;
    margin: 20px;
    font-weight: bold;
    cursor: pointer;
    transition: 0.5s ease-in-out;
  }

  label {
    //padding-left: 10px;
    font-size: 12px;
    color: #ccc;
    cursor: pointer;
  }

  input:not([type="checkbox"]),
  textarea {
    font-size: 16px;
    width: 100%;
    height: 40px;
    justify-content: center;
    display: flex;
    margin: 6px auto;
    padding: 6px 10px;
    border: none;
    outline: none;
    border-radius: 0px 5px 5px 0px;
  }

  textarea {
    height: auto;
    border-radius: 5px;
    resize: none;
  }

  input[type="submit"] {
    border-radius: 5px;
  }
`;

export const InputWrapper = styled.div`
  position: relative;

  .liveValidateMessage {
    position: absolute;
    top: -5px;
    left: 0;
    background-color: #f1a9a9;
    padding: 5px;
    opacity: 1;
    animation: fadeIn 0.5s linear;
    border-radius: 5px 5px 0px 0px;
  }

  @keyframes fadeIn {
    0%,
    50% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

export const FieldWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;

  svg {
    padding: 8px;
    background: ${(props) => (props.reg === false ? ({ theme }) => theme.buttonColor.light.color : ({ theme }) => theme.buttonColor.light.color)};

    color: ${(props) => (props.reg === false ? ({ theme }) => theme.buttonColor.light.color : ({ theme }) => theme.buttonColor.dark.color)};
    width: 40px;
    height: 40px;
    text-align: center;
    border-radius: 5px 0px 0px 5px;
  }
`;

export const FormContainer = styled.div`
  ${NoSelect}

  width: 100%;
  height: ${formHeightDesktop};
  @media (max-width: ${({ theme }) => theme.mobile}) {
    height: ${formHeightMobile};
  }
  position: relative;
  overflow: hidden;

  ${BorderShadow}

  z-index: 5;
`;

export const SecondaryForm = styled(Form)`
  position: absolute;
  width: 100%;
  height: ${formHeightDesktop};
  z-index: 10;
  background: ${({ theme }) => theme.colors.secondaryColor};
  border-radius: 80% / 10%;

  transform: translateY(${(props) => (props.reg === false ? "60px" : "510px")});

  @media (max-width: ${({ theme }) => theme.mobile}) {
    height: ${formHeightMobile};
    transform: translateY(${(props) => (props.reg === false ? "30px" : "470px")});
  }
  transition: 0.8s ease-in-out;

  span {
    ${NoSelect}
    color: ${({ theme }) => theme.buttonColor.color};
    padding-top: 15px;
  }
`;

export const PrimaryForm = styled(Form)`
  position: absolute;
  top: 0;
  width: 100%;

  height: ${formHeightDesktop};
  @media (max-width: ${({ theme }) => theme.mobile}) {
    height: ${formHeightDesktop};
  }

  span {
    color: ${({ theme }) => theme.buttonColor.light.bgcolor};

    font-style: normal;
  }
`;

export const FormWrapper = styled.div`
  position: absolute;
  left 50%;
  top: 50%;
  transform: translate(-50% , -50%);


  width: 80%;

  @media (max-width: ${({ theme }) => theme.mobile}) {
    width: 85%;
  }
`;

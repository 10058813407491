import React, { useEffect, Suspense, lazy, useContext } from "react";
import { isMobile } from "react-device-detect";
import { Routes, Route } from "react-router-dom";
import { BodyContainer } from "./Container.styled";

import { useLocation } from "react-router-dom";

import StateContext from "../Appcontext/StateContext";
import DispatchContext from "../Appcontext/DispatchContext";

import Modal from "../components/Modal/Modal";

const Header = lazy(() => import("../components/Header"));

const Notfound = lazy(() => import("../Pages/Notfound"));

const AppRouter = () => {
  const appState = useContext(StateContext);

  const appDispatch = useContext(DispatchContext);

  const { pathname } = useLocation();

  useEffect(() => {
    appDispatch({ type: "isMobile", value: isMobile ? true : false });
  }, [appDispatch]);

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Modal showmodal={appState.showmodal} />
      <BodyContainer

      >
        <Header />
        <Routes>
          {appState.Approutes.map((item, i) =>
            item.component && !item.private ? (
              <Route key={i} path={item.path} element={item.component}>
                {item.NestedRoutes && item.NestedRoutes.map((nested, y) => <Route key={y} path={nested.path} element={nested.component} />)}
              </Route>
            ) : (
              <></>
            )
          )}

          <Route path="*" element={<Notfound />} />
        </Routes>
      
      </BodyContainer>
    </Suspense>
  );
};

export default AppRouter;

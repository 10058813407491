import React from "react";
import InputField from "../InputField";
import styled from "styled-components";
import { UiToggle } from "../../styles/Uitoggle.Styled";

const ThemeToggler = styled.div`
  display: flex;
  align-items: center;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 20;
`;
export const Toggler = ({ toggleTheme, theme }) => {
  return (
    <ThemeToggler>
      🌞
      <InputField name="darkmode">
        <UiToggle>
          {/* <input type="checkbox" id="darkmode" name="darkmode" checked={localStorage.getItem("theme") === "dark"} onChange={toggleTheme} /> */}
          <input type="checkbox" id="darkmode" name="darkmode" checked={theme === "dark"} onChange={toggleTheme} />
          <label htmlFor="darkmode">
            <div></div>
          </label>
        </UiToggle>
      </InputField>
      🌙
    </ThemeToggler>
  );
};

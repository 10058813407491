import styled from "styled-components";
import { BorderShadow } from "../styles/BorderShadow";
const Row = styled.div`
  ${(props) => (props.BorderShadow === true ? BorderShadow : "")}
  border: solid 1px #ff0000;
  grid-column-start: ${(props) => (props.sd ? props.sd : "1")};
  grid-column-end: span ${(props) => (props.ed ? props.ed : "12")};
  padding: ${(props) => (props.paddingDefault ? props.paddingDefault : 0)};
  margin: ${(props) => (props.marginDefault ? props.marginDefault : 0)};
  background-color: ${(props) => (props.bgColor ? props.bgColor : "")};

  @media ${({ theme }) => theme.breakpoints.m} {
    grid-column-start: ${(props) => (props.sm ? props.sm : "1")};
    grid-column-end: span ${(props) => (props.em ? props.em : "6")};
    padding: ${(props) => (props.paddingMedium ? props.paddingMedium : 0)};
    margin: ${(props) => (props.marginMedium ? props.marginMedium : 0)};
  }

  @media ${({ theme }) => theme.breakpoints.s} {
    grid-column-start: ${(props) => (props.ss ? props.ss : "1")};
    grid-column-end: span ${(props) => (props.es ? props.es : "6")};
    padding: ${(props) => (props.paddingSmall ? props.paddingSmall : 0)};
    margin: ${(props) => (props.marginSmall ? props.marginSmall : 0)};
  }
`;

export default Row;

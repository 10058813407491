import React from "react";

import { ThemeProvider } from "styled-components";
import { useImmerReducer } from "use-immer";
import GlobalStyles from "../styles/Global";

import DispatchContext from "../Appcontext/DispatchContext";
import StateContext from "../Appcontext/StateContext";
import { lightTheme, darkTheme } from "./Theme";
import { useDarkMode } from "../components/DarkmodeSwitch/useDarkMode";

import { Toggler } from "../components/DarkmodeSwitch/Toggler";

const AppProvider = ({ children }) => {
  const [theme, themeToggler] = useDarkMode();

  const themeMode = theme === "light" ? lightTheme : darkTheme;

  const initialState = {
    loggedIn: Boolean(localStorage.getItem("user")),
    flashMessageges: [],
    user: JSON.parse(localStorage.getItem("user")),
    uid: { value: "", hasErrors: false, message: "", checkCount: 0 },
    password: { value: "", hasErrors: false, message: "", checkCount: 0 },
    submitCount: 0,
    Approutes: [],
    IsMobile: localStorage.getItem("ismobile"),

    landingpageHeight: "70vh",

    showBurger: false,
    showmodal: "none",
    ModalPayload: "",
    dayornight: "",
  };

  const ourReducer = (draft, action) => {
    switch (action.type) {
      case "pushRoutes":
        draft.Approutes = [];
        action.value.forEach((e) => {
          draft.Approutes.push(e);
        });
        return;

      case "isMobile":
        draft.IsMobile = localStorage.setItem("ismobile", action.value);
        return;

      case "hideform":
        draft.hideform = action.value;
        return;

      case "ToggleModal":
        draft.showmodal = action.value;

        draft.ModalPayload = action.payload;
        return;
      case "closemenu":
        draft.showBurger = false;

        return;

      case "dayornight":
        draft.dayornight = action.value;

        return;

      case "toggleBurger":
        draft.showBurger = !draft.showBurger;
        return;

      default:
        return;
    }
  };

  const [state, dispatch] = useImmerReducer(ourReducer, initialState);

  return (
    <StateContext.Provider value={state}>
      <DispatchContext.Provider value={dispatch}>
        <ThemeProvider theme={themeMode}>
          <GlobalStyles />
          <Toggler theme={theme} toggleTheme={themeToggler} />
          {children}
        </ThemeProvider>
      </DispatchContext.Provider>
    </StateContext.Provider>
  );
};

export default AppProvider;





import AppProvider from "./AppProvider/AppProvider";

import AppRouter from "./AppRouter/AppRouter";

function App() {
 
  return (
    <AppProvider>
      <AppRouter />
    </AppProvider>
  );
}

export default App;

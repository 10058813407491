import styled from "styled-components";

import { BorderShadow } from "../styles/BorderShadow";

const FullWidthSection = styled.section`
  ${(props) => (props.BorderShadow ? BorderShadow : "")}

  grid-column: 1 / span 14;
  display: grid;
  grid-template-columns: 1fr repeat(12, minmax(auto, 4.2rem)) 1fr;
  grid-template-rows: max-content;
  gap: ${(props) => (props.gapDefault ? props.gapDefault : "2rem 2rem")};
  margin: ${(props) => (props.marginDefault ? props.marginDefault : 0)};
  padding: ${(props) => (props.paddingDefault ? props.paddingDefault : 0)};

  @media ${({ theme }) => theme.breakpoints.m} {
    grid-column: 1 / span 8;
    grid-template-columns: 2rem repeat(6, 1fr) 2rem;
    gap: ${(props) => (props.gapMedium ? props.gapMedium : "1rem 1rem")};
    margin: ${(props) => (props.marginMedium ? props.marginMedium : 0)};
    padding: ${(props) => (props.paddingMedium ? props.paddingMedium : 0)};
  }

  @media ${({ theme }) => theme.breakpoints.s} {
    grid-template-columns: 1rem repeat(6, 1fr) 1rem;
    gap: ${(props) => (props.gapSmall ? props.gapSmall : "1rem 1rem")};
    margin: ${(props) => (props.marginSmall ? props.marginSmall : 0)};
    padding: ${(props) => (props.paddingSmall ? props.paddingSmall : 0)};
  }
`;

export default FullWidthSection;

const primaryColor = "#44546A";
const secondaryColor = "#6386B8";
const cta = "#A7B86C";
const fourth = "#616B3A";


export const lightTheme = {
  colors: {
    header: "#ff00cc",
    body: "#fffff0",
    text: "#000000",
    footerTop: "#252526",
    footerbottom: primaryColor,
    secondaryColor: secondaryColor,
    primaryColor: primaryColor,
    glassesColor: primaryColor,
    cta: cta,
    h1: secondaryColor,
    h2: secondaryColor,
    h3: fourth,
    h4: primaryColor,
    h5: "",
    h6: "",
    barColor: primaryColor,
    burgermenu: primaryColor,
  },
  iconColor: {
    light: { bgcolor: primaryColor, color: "#fff" },
    dark: { bgcolor: "#fff", color: primaryColor },
  },
  buttonColor: {
    light: { bgcolor: primaryColor, color: "#fff" },
    dark: { bgcolor: "#fff", color: primaryColor },
  },
  cvcolor: { color: "#fff", background: primaryColor, bodybackground: "#fff", eduiconfill: secondaryColor },
  breakpoints: {
    s: "only screen and (max-width: 40rem)",
    m: "only screen and (max-width: 50rem)",
  },
  desktop: "1200px",
  mobile: "768px",
  logo: "60px",
  landinganimation: { fill: secondaryColor },
  games: { backgroundcolor: "#ccc", bordercolor: cta },
  alerts: secondaryColor,
};


const ctaDark = "#BB86FC";

export const darkTheme = {
  colors: {
    header: "#ff00cc",
    body: "#5E4CD4",
    text: "#fffff0",
    footerTop: "#292932",
    footerbottom: "#1C1C24",
    glassesColor: ctaDark,
    secondaryColor: secondaryColor,
    primaryColor: primaryColor,
    cta: ctaDark,
    h1: secondaryColor,
    h2: secondaryColor,
    h3: fourth,
    h4: secondaryColor,
    h5: ctaDark,
    h6: ctaDark,
    barColor: ctaDark,
    burgermenu: ctaDark,
  },
  logo: "60px",
  iconColor: {
    light: { bgcolor: primaryColor, color: "#fff" },
    dark: { bgcolor: "#fff", color: primaryColor },
  },
  buttonColor: {
    light: { bgcolor: primaryColor, color: "#fff" },
    dark: { bgcolor: "#fff", color: primaryColor },
  },
  cvcolor: { color: primaryColor, background: "#292932", bodybackground: "#1C1C24", eduiconfill: ctaDark },
  breakpoints: {
    s: "only screen and (max-width: 40rem)",
    m: "only screen and (max-width: 50rem)",
  },
  desktop: "1200px",
  mobile: "768px",

  landinganimation: { fill: ctaDark },
  games: { backgroundcolor: "#333", bordercolor: cta },
  alerts: cta,
};

import React, { useContext } from "react";

import DispatchContext from "../../Appcontext/DispatchContext";
import StateContext from "../../Appcontext/StateContext";
import { ModalStyled } from "./Modal.Styled";
import FullWidthSection from "../../AppLayout/FullWidthSection";
import Grid from "../../AppLayout/Grid";
import Row from "../../AppLayout/Row";

const Modal = (props) => {
  const appDispatch = useContext(DispatchContext);
  const appState = useContext(StateContext);

  return (
    <ModalStyled showmodal={props.showmodal}>
      <Grid as="main">
        <FullWidthSection>
          <Row sd={1} ed={14} sm={1} em={6} ss={1} es={6}>
            <span onClick={() => appDispatch({ type: "ToggleModal", value: "none" })}>&times;</span>
            {appState.ModalPayload}
          </Row>
        </FullWidthSection>
      </Grid>
    </ModalStyled>
  );
};

export default Modal;

import { createGlobalStyle } from "styled-components";

const GlobalStyles = createGlobalStyle`



*{
  box-sizing: border-box;
  //border: solid 1px;
}

html {
  height: 100vh;
  overflow: hidden;
  max-width: 480px;
  margin: auto;
}

  



body {

  height: 100vh;



    @font-face {
    font-family: "myFirstFont";
    src: url("./Fonts/Montserrat");
}
 
  font-family: 'Montserrat', sans-serif;


  font-size: ${(props) => (props.size ? "" : "18px")};
  line-height: 20px;




  margin: 0 auto;

 color: ${({ theme }) => theme.colors.text};
 background-color: ${({ theme }) => theme.colors.body};

 p{
   margin: 8px 0;
 }

}


input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  //border: 1px solid green;
  //-webkit-text-fill-color: green;
  -webkit-box-shadow: 0 0 0px 1000px #fff inset;
  transition: background-color 5000s ease-in-out 0s;
}

`;

export default GlobalStyles;

import React from "react";


import { FieldWrapper, InputWrapper } from "../styles/Forms.Styled";

const InputField = (props) => {


  return (
    <InputWrapper>
      {props.label && <label htmlFor={props.name}>{props.label}</label>}

      <FieldWrapper reg={props.reg}>{props.children}</FieldWrapper>
    </InputWrapper>
  );
};

export default InputField;



export const BorderShadow = () => {

  return `

  border-radius: 10px;
  border: 1px solid #cccccc;
  box-shadow: 1px 1px 5px 1px #cccccc;
  background-color:  "#fff"}};
  
  `;
};
